<template>
  <b-card>
    <b-list-group>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Sipariş No
        </div>
        <div>{{ dataItem.onum }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Sipariş Durumu
        </div>
        <div>{{ dataItem.order_status }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Ara Toplam
        </div>
        <div>{{ dataItem.subTotal | toCurrency }}₺</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Vergiler Toplamı
        </div>
        <div>{{ dataItem.taxesTotal }}₺</div>
        <div class="font-small-2 text-muted">
          (KDV %{{ dataItem.tax_rate }})
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Genel Toplam
        </div>
        <div>{{ dataItem.grandTotal | toCurrency }}₺</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Sipariş Tarihi
        </div>
        <div>{{ moment(dataItem.created).format('llll') }}</div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealerOrders/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('dealerOrders/getData', {
        where: {
          'dealer_orders.id': this.$route.params.id,
        },
        array: false,
      })
    },
  },
}
</script>
